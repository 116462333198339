<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
   <router-view :connected="connected"></router-view>

</template>

<style>
  h4 {font-weight: 700;}
  .layout--full-page .full-page-bg-color {
    background-color: #e0e0e0;
  }
</style>
<script>
import navbar from '../components/navbar/TheNavbarHorizontal.vue';
import auth from '@/auth';
import Input from '../../views/forms/form-elements/input/Input';

export default {
  data() {
    return {
      type: '',
      componentKey: 0,
      test: auth.user.authenticated,
      user: auth.user,
      connected: auth.user.authenticated,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
  },
  components: {
    Input,
    'the-navbar-horizontal': navbar,
  },
};

</script>
